<div class="max-w-[1280px] mx-auto">
  @if(serverRestarting$ | async){
  <div
    class="fixed flex place-items-center w-fit left-[50%] translate-x-[-50%] rounded-md cursor-pointer top-2 px-3 py-2 bg-white text-black z-[1000]"
  >
    <button (click)="reloadCurrentRoute()">Click here to Refresh</button>
    <mat-icon class="ml-2">refresh</mat-icon>
  </div>
  }
  <!-- <div
    *ngIf="!isAndroid()"
    class="h-[56px] w-full"
    [ngClass]="headerBg()"
  ></div> -->
  @if(!hideStatusBar$()){
  <div
    *ngIf="!isAndroid()"
    class="h-[15px] w-full"
    [ngClass]="headerBg()"
  ></div>
  }
  <div *ngIf="routeLoading()?.loading" class="w-full fixed top-0 z-[1000]">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <app-no-internet class="z-[10001]" *ngIf="!isOnline()"></app-no-internet>

  <div>
    <router-outlet></router-outlet>
  </div>

  <!-- New floating navigation bar -->

 <!-- Navigation bar with fixed sizing and more transparent backgrounds -->
@if((showNavBar())){
  <div class="fixed bottom-0 w-full z-50">
    <div class="mx-auto rounded-[30px] flex justify-around items-center py-3 px-2 bg-[#3D4558] max-w-lg shadow-lg">
      <!-- Home Button -->
      <button 
        [routerLink]="['/home/categories']" 
        class="flex items-center justify-center relative transition-all duration-200"
        [ngClass]="activeRoute() === '/home/categories' ? 'bg-[#FE565E] rounded-[30px] px-3 py-2 gap-2' : ''">
        <div 
          [ngClass]="activeRoute() !== '/home/categories' ? 'bg-white/10 rounded-full p-2.5 flex items-center justify-center' : ''">
          <img 
            class="h-5 w-5 brightness-0 invert" 
            src="assets/bottomnav/home.png" 
            alt="home" />
        </div>
        <span *ngIf="activeRoute() === '/home/categories'" class="text-white font-bold text-sm whitespace-nowrap">Home</span>
      </button>
      
      <!-- Forum/Discussions Button -->
      <button 
        [routerLink]="['/home/forum']" 
        class="flex items-center justify-center relative transition-all duration-200"
        [ngClass]="activeRoute() === '/home/forum' ? 'bg-[#FE565E] rounded-[30px] px-3 py-2 gap-2' : ''">
        <div 
          [ngClass]="activeRoute() !== '/home/forum' ? 'bg-white/10 rounded-full p-2.5 flex items-center justify-center' : ''">
          <img 
            class="h-5 w-5 brightness-0 invert" 
            src="assets/bottomnav/forum.png" 
            alt="forum" />
        </div>
        <span *ngIf="activeRoute() === '/home/forum'" class="text-white font-bold text-sm whitespace-nowrap">Discussions</span>
        @if(notificationsCount$().FORUM + messageCount() > 0){
        <span class="absolute -top-1 -right-1 bg-red-700/70 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
          {{(notificationsCount$().FORUM + messageCount()) > 99 ? '99+' : notificationsCount$().FORUM + messageCount()}}
        </span>
        }
      </button>
      
      <!-- Achievements Button - With fixed width to prevent stretching -->
      <button 
        [routerLink]="['/home/achievements']" 
        class="flex items-center justify-center relative transition-all duration-200"
        [ngClass]="activeRoute() === '/home/achievements' ? 'bg-[#FE565E] rounded-[30px] py-2 gap-2 px-2 max-w-[140px]' : ''">
        <div 
          [ngClass]="activeRoute() !== '/home/achievements' ? 'bg-white/10 rounded-full p-2.5 flex items-center justify-center' : ''">
          <img 
            class="h-5 w-5 brightness-0 invert" 
            src="assets/bottomnav/achievements.png" 
            alt="achievement" />
        </div>
        <span *ngIf="activeRoute() === '/home/achievements'" class="text-white font-bold text-sm truncate">Achievements</span>
      </button>
      
      <!-- Profile Button -->
      <button 
        [routerLink]="['/home/profile']" 
        class="flex items-center justify-center relative transition-all duration-200"
        [ngClass]="activeRoute() === '/home/profile' ? 'bg-[#FE565E] rounded-[30px] px-3 py-2 gap-2' : ''">
        <div 
          [ngClass]="activeRoute() !== '/home/profile' ? 'bg-white/10 rounded-full p-2.5 flex items-center justify-center' : ''">
          <img 
            class="h-5 w-5 brightness-0 invert" 
            src="assets/bottomnav/profile.png" 
            alt="profile" />
        </div>
        <span *ngIf="activeRoute() === '/home/profile'" class="text-white font-bold text-sm whitespace-nowrap">Profile</span>
      </button>
    </div>
  </div>
}

@if(fullScreenLoading()) {
  <div class="absolute top-0 h-screen w-screen backdrop-blur-lg backdrop-brightness-75">
    <div class="h-full w-full grid justify-center items-center bg-black/50">
      <div class="flex flex-col items-center gap-6">
        <img 
          src="assets/loading.gif" 
          alt="Loading"
          class="w-64 h-64 object-scale-down"
        />
        <p class="text-center font-raleway font-medium text-lg leading-[21.13px] text-white">
          Loading content<br>
          Please wait...
        </p>
      </div>
    </div>
  </div>
  }
  
  
<ng-template
  class="flex flex-col text-white gap-16 w-full rounded-none dialog-frame2"
  #updateDialog
>
  <div class="flex flex-col p-4 gap-10 mt-10">
    <img
      class="w-[316px] h-[282px] self-center"
      src="../assets/images/update.gif"
      alt=""
    />
    <div class="text-[32px] text-center text-white font-extrabold mt-5">
      Update Your App
    </div>
    <div
      class="text-center text-[20px] leading-relaxed overflow-y-scroll font-semibold text-white"
    >
      To enjoy our newest app features tap the button below
    </div>
  </div>
  <div class="flex flex-col justify-between mt-10">
    <button
      (click)="openStore()"
      class="bg-[#FE565E] h-[60px] p-2 mx-auto w-[276px] text-white self-center place-items-center text-[20px] font-bold rounded-[10px]"
    >
      Update Now
    </button>
    @if(!shouldUpdate$()){

    <button
      (click)="closeDialog(updateDialog)"
      class="p-2 mx-auto w-[135px] text-white self-center place-items-center text-[20px] font-bold rounded-[10px]"
    >
      Skip
    </button>
    }
  </div>
</ng-template>
